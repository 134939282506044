import React,{useState, useEffect} from "react";
import {FiX,FiUsers} from 'react-icons/fi';
import { format } from "date-fns";
import {del,get} from '../../store/config/register';
import { toast } from "react-toastify";
import { useHistory }  from "react-router-dom";
import './ModalAgendado.css'

export default function ModalAgendado({conteudo,close}) {
    const [agenda,setAgenda]=useState(conteudo.agenda)
    const [maiorHoje,setMaiorHoje]= useState(false);
    const [espaco,setEspaco]=useState(conteudo.espaco)
        const history =useHistory();


      let hoje =new Date().setHours(0,0,0,0);

        useEffect(()=> {
            if(agenda.start >= hoje){
                setMaiorHoje(true);
            }
        },[])
   

    async function removerAgenda() {
      await del('/EspacoAgenda/v1/'+ agenda.id)
      await toast.success('Agendamento apagado com sucesso!')

      window.location.reload();
    }

    function convidadoAgenda(){
        history.push('/ReservaEspaco/Convidado/'+ agenda.id)
    }

return (
    <div>
        <div className="modal3">
            <div className="container">
                <button className="closeAgenda" onClick={ close }>
                    <FiX size={23} color="#FFF" />
                    Voltar
                </button>
                <label>{agenda.title}</label>
                <br />
                <label>{format(agenda.start,"dd/MM/yyyy HH:mm")}</label>
                <hr />
                {maiorHoje === true && agenda.unidade_id === agenda.usuario_unidade && (
                     <div className="remover">                    
                     <button className=" btn btn-danger" onClick={(e) => removerAgenda()}>Remover</button>
                     {espaco.lista_Convidado === true  && (
                            <button  className=" btn btn-light" onClick={ (e) => convidadoAgenda() }>
                            <FiUsers size={23} color="#000" />
                                Convidados
                            </button>
                        )}
                       
                     </div>
                )}
            </div>
        </div>
    </div>
);
}