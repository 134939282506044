import { showToast} from './components/Toast/Toast';


//export const baseApiUrl='https://localhost:44335/api'
export const baseApiUrl='https://webapicondominio.herokuapp.com/api'
export const user = '__knowledge_user'
export const Key = '__knowledge_user'
export const authKey='authKey'
export const secretKey='@condo4796'
export const userKey = '{"usuario":"William","senha":"Teste"}'

export function showError(e){
    if(e && e.response && e.response.data){
        showToast({type:'error', message: e.response.data})
    }else if (typeof e === 'string'){
        showToast({type:'error', message: e})

    }else{
        console.log(e);
        showToast({type:'error', message: "Erro padrão"})
    }
}