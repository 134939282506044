import React from "react";
import { useHistory } from "react-router-dom";

export default function ButtonImage({conteudo}) {
    const history = useHistory();
    let opacity =conteudo.color === "#808080" ? 0.3 : 1;
    return (
        <div className='menu' style={{backgroundColor: conteudo.color,opacity: opacity}}>
            <span>
            <img className='mnIcon' src={conteudo.image} onClick={() => window.location.href=conteudo.link}/>
            </span>

            <div className='link'>
                <span>
                <img className='mnIcon' src={conteudo.image} onClick={() => window.location.href=conteudo.link}/>
                <div className="conteudo">
                {conteudo.nome}
                </div>   
                </span>
            </div>
        </div>
    );
}