import React,{useState, useEffect} from "react";
import { useHistory,useParams} from "react-router-dom";
import { toast } from 'react-toastify';
import {FiX,FiEdit2} from 'react-icons/fi';
import {get,post,put} from '../../../store/config/register';
import Messagem from "../../../components/mensagem/Messagem";
import './HorarioPadrao.css'

const initialState={
       horaPadrao: {
     espaco_Id: 0,
     dia_Semana: 0,
     hora_Inicio: '08:00',
     hora_Fim: '18:00'   
    },
    diaSemana: {
      dom: false,
      seg: false,
      ter: false,
      qua: false,
      qui: false,
      sex: false,
      sab: false
     }
   }

export default function HorarioPadrao(){
    const {id}=useParams();
    const [horaPadrao,setHoraPadrao] = useState(initialState.horaPadrao);
    const [horaPadraos,setHoraPadraos] =useState([]);
    const [showPostMensagem, setShowPostMessagem] = useState(false);
    const [messagem,setMessagem]=useState({});
    const [diasSemana,setDiasSemana] = useState(initialState.diaSemana);
    const dias = [{id: 1,nome:"Domingo"}, {id: 2,nome:"Segunda"}, {id: 3,nome:"Terça"}, {id: 4,nome:"Quarta"}, {id: 5,nome:"Quinta"}, {id: 6,nome:"Sexta"}, {id: 7,nome:"Sabado"}]

    useEffect(()=>{
        CarregaHorasPadrao();
    },[])

    async function CarregaHorasPadrao(){
        if(id !== undefined){
             let hrs = await get('/EspacoPeriodoPadrao/v1/Espaco/'+id);
             if(hrs !== undefined) {
                  setHoraPadraos(hrs);
             }
        }
        
        }

    function  updateFieldHoraPadrao(event){
        const hor ={...horaPadrao}
        hor[event.target.name] =event.target.value
             setHoraPadrao(hor);
   }

   function TrocaDiaPadrao(e){
    setHoraPadrao({...horaPadrao, dia_Semana: parseInt(e.target.value)})
}

function renderDias() {
    if(dias !== undefined) {
         return dias.map(dia => {
              return (<option value={dia.id} key={dia.id}> {dia.nome} </option>)
         })
    }
}

function TablePeriodoPadrao() {
    return (
         <div className="table2">
              <table>
      <thead>
        <tr>
          <th scope="col">Dia da Semana</th>
          <th scope="col">Início</th>
          <th scope="col">Fim</th>
          <th scope="col"></th>
        </tr>
      </thead>
        <tbody>
          {horaPadraos === undefined ? null :horaPadraos.map((item, index)=>{
            return(
              <tr key={index}>
                <td data-label="Dia da Semana">{item.semana}</td>
                <td data-label="Início">{item.hora_Inicio.toString().substring(0,5)}</td>
                <td data-label="Fim">{item.hora_Fim.toString().substring(0,5)}</td>
                <td  data-label="">
                <button className="action" style={{backgroundColor: '#F99E08' ,marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={(e) => {Edit(item.espaco_Id,item.dia_Semana)}} >
                  <FiEdit2 color="#FFF" size={17} />
                  </button>
                  <button className="action" style={{backgroundColor: '#ff0000', marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir"  onClick={async(e) => await togglePostMessagemModal(item)} >
                  <FiX color="#FFF" size={17} />
                  </button>
                  </td>
              </tr>
            )     
          })}
        </tbody>
      </table>
         </div>
    );
}

async function Edit(espaco_id,dia_semana){
    const per = await get(`/EspacoPeriodoPadrao/v1/Filtro?espaco_id=${espaco_id}&dia_semana=${dia_semana}`)
    let dia ={...diasSemana}
    dia =initialState.diaSemana;
    dia[retornaDsDia(dia_semana)]=true;

    setDiasSemana({...dia});
      if(per !== undefined){
        setHoraPadrao({...per});
      }
   }

   function retornaDia(dia){

    let id =0;
    switch(dia){
      case 'dom':
        id=1;
        break;
      case 'seg':
        id=2;
        break;
      case 'ter':
        id=3;
        break;
      case 'qua':
        id=4;
        break;
      case 'qui':
        id=5;
        break;
      case 'sex':
        id=6;
        break;
      case 'sab':
        id=7;
        break;
    }

    return id;

   }

   function retornaDsDia(dia){

    let descricao ='';
    switch(dia){
      case 1:
        descricao='dom';
        break;
      case 2:
        descricao='seg';
        break;
      case 3:
        descricao='ter';
        break;
      case 4:
        descricao='qua';
        break;
      case 5:
        descricao='qui';
        break;
      case 6:
        descricao='sex';
        break;
      case 7:
        descricao='sab';
        break;
    }

    return descricao;

   }

   async function inserir(item){
       horaPadrao.espaco_Id =parseInt(id);
          horaPadrao.dia_Semana =retornaDia(item);
          console.log(horaPadrao.dia_Semana)
            let hora =  await get(`/EspacoPeriodoPadrao/v1/Filtro?espaco_id=${id}&dia_semana=${horaPadrao.dia_Semana}`)

            if(hora === null){
              await    post('/EspacoPeriodoPadrao/v1',horaPadrao);
            }else {
               await  put('/EspacoPeriodoPadrao/v1/'+id,horaPadrao)
            }
   }

   async function incluirHorarioPadrao(){

    let dias =Object.keys(diasSemana);

      for(const item of dias) {

      let dia = diasSemana[item];
      
      if(dia === true){
          if(id !== undefined) {
         
              await inserir(item);
           
            }
      }
    }
    
   
    await toast.success('Dados salvo sucesso.')  
    window.location.reload();


    
   
  }

  function togglePostMessagemModal(item){
    setMessagem({...item, messagem:'Confirma exclusão deste dia de semana '+ item.semana +" ?",type: "EspacoPeriodoPadrao"})
    setShowPostMessagem(!showPostMensagem) //trocando de true pra false
  }

  function checkeddias(e){
    let dia={...diasSemana};
    dia[e.target.name]=e.target.value ==='true'? false : true;
    setDiasSemana({...dia})
  }
    function renderForm(){
        return (
            <div>
              <div>
                <div className="checkbox">
                  {dias.map((item,index)=>{
                      let nome = item.nome.toLowerCase().substring(0,3)
                    return (
                   <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={nome}
                    value={diasSemana[nome]}
                    checked={diasSemana[nome] === true}
                    onChange={(e) => checkeddias(e)}
                  />
                <div className="right-section">{ nome}</div>
                </div>
                    );
                  })}
                </div>
               
                <div className="hora">
                <label >Início: </label>
                <input type='time' min="00:00" max="22:00" name="hora_Inicio" value={horaPadrao.hora_Inicio} onChange={(e) =>updateFieldHoraPadrao(e)}  />
                <label >Fim: </label>
                <input type='time' min="00:00" max="22:00" name="hora_Fim" value={horaPadrao.hora_Fim} onChange={(e) =>updateFieldHoraPadrao(e)} />                                    
                </div>
                <button className="btn btn-warning ml-2" id="btnIncluirHorario" onClick={async ()=> await incluirHorarioPadrao()}>Incluir </button>
              </div>
              {showPostMensagem && (
                  <Messagem
                  conteudo={{...messagem}}
                  close={togglePostMessagemModal}
                  />
                  )}
                  <br />
                  {TablePeriodoPadrao()}
            </div>
        );   
        
   }
    return (
        <div>
            {renderForm()}
        </div>
    );
}