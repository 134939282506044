import React, { useState } from 'react'
import './ModalEspaco.css'


export default function ModalEspaco ({origem, close}) {
     const [dados, setDados] = useState()


     function volta() {
          close(origem)
     }



     return (
          <div className='modal1'>
                    
               <div className='container'>
                    <div>
                         <label className="control-label"><strong>Informações sobre o espaço escolhido:</strong></label><br/>
                         <label>Antecedência máxima para reserva (em dias): </label>
                         <div>
                              <input type="text" id="dias" name="diasMaximo" disabled/>
                         </div>

                         <label>Antecedência mínima para reserva (em dias): </label>
                         <div>
                              <input type='text' id="dias" name="diasMinimo" disabled>
                              </input>
                         </div>

                         <label>Prazo para desistência (em horas):</label>
                         <div>
                              <input type='text' id="dias" name="diasMinimo" disabled>
                              </input>
                         </div>

                         <label>Capacidade máxima:</label>
                         <div>
                              <input type='text' id="dias" name="numero" disabled>
                              </input>
                         </div>

                         <label>Taxa de uso (R$):</label>
                         <div>
                              <input type='text' id="taxa" name="complemento" disabled>
                              </input>
                         </div>

                         <div className='tabelaModal'>
                              <label className='lblModal'>Dias/Horários de Funcionamento</label>
                              <table id="table2"> 
                              <thead>
                                   <tr>
                                        <th scope="col">Dia da semana</th>
                                        <th id="fim" scope="col">Início</th>
                                        <th scope="col">Fim</th>
                                   </tr>
                              </thead>

                              <tbody>
                                   <tr>
                                        <td data-label="Dia da semana">Domingo</td>
                                        <td data-label="Inicio">11:00</td>
                                        <td data-label="Fim">15:00</td>
                                   </tr>

                                   <tr>
                                        <td data-label="Dia da semana">Domingo</td>
                                        <td data-label="Inicio">17:00</td>
                                        <td data-label="Fim">21:00</td>
                                   </tr>
                              </tbody>
                              </table>
                         </div>
                    </div>

                    <div>
                         <button className="btnModal btn-primary ml-2" onClick={() => {close()}}>
                              Ok
                         </button>
                    </div>

               </div>

          </div>
     )
}

