import React from "react";
import './ButtonAgenda.css'
import { useHistory } from "react-router-dom";


export default function ButtonAgenda({conteudo}) {

    const history = useHistory();

    return (
        <div className='agenda' >
            <label>{conteudo.nome}</label>
            <div className="paramAgenda">
                <div className="capacidade">
                    CAPACIDADE
                    <br/>
                    <i className="fa fa-users"></i><strong>{conteudo.capacidade}</strong> máx.
                </div>

                <div className="taxa">
                    TAXA DE RESERVA
                    <br/>
                    <strong>R$ { conteudo.taxa }</strong> 
                </div>
               
            </div>

            <div className="paramAgenda">
                <div className="reserva">
                    RESERVA
                    <br/>
                    <i className=" fa fa-clock-o"></i><strong>{conteudo.antecedencia_Minima}h </strong>
                    antes do evento
                </div>


                <div className="canc">
                    CANCELAMENTO
                    <br/>
                    <i className=" fa fa-clock-o"></i><strong>{conteudo.desistencia}h </strong>
                    antes do evento
                </div>

              
            </div>
            <div className="buttonAgenda">
                <button onClick={() => history.push('/ReservaEspaco/'+ conteudo.id)}>
                    <i className="fa fa-calendar"/>
                    RESERVAR
                    </button>
            </div>
        </div>
    );

}