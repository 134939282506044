import React from "react";
import '../../pages/Qrcode/Qrcode.css'
import { del } from "../../store/config/register";
import { toast } from "react-toastify";

export default function Messagem({conteudo,close}) {

    async function Delete(Id,type){

      if(type === 'Visitante'){
        await  del(`/Visitante/v1/${Id}`)
        await toast.success('Visitante apagado.')
      }  else if (type === 'EspacoPeriodoPadrao'){
        await  del(`/EspacoPeriodoPadrao/v1/Filtro?espaco_id=${conteudo.espaco_Id}&dia_semana=${conteudo.dia_Semana}`)
        await toast.success('Horário apagado.')
      }else if(type === 'EspacoPeriodoHora'){
        await  del(`/EspacoPeriodoHora/v1/${Id}`)
        await toast.success('Horário apagado.')
      }
      
       window.location.reload();
      }

    return (
        <div className="modalQrcode">
            <div className="containerQrcode">
                <label>{conteudo.messagem}</label>
                <div id="botao">
                <button className="btn btn-primary ml-2" onClick={async()=> {await Delete(conteudo.id, conteudo.type); }}>Sim</button>
                <button className="btn btn-secondary ml-2" onClick={close}>Não</button>
                </div>
                
            </div>
        </div>
    );
}