import CryptoJS from "crypto-js";
import { getLocalStorage } from "./storage";
import { authKey,secretKey } from "../../global";


export const user = getLocalStorage(authKey) === undefined ?  undefined : JSON.parse(getLocalStorage(authKey));

const data = user === undefined ? undefined : VerificaUsuario();
export const login_Id =  user === undefined ? undefined : parseInt(data.split('/')[0]);
export const empresa_Id =  user === undefined ? undefined : parseInt(data.split('/')[2]);

  function VerificaUsuario(){
    let bytes  = CryptoJS.AES.decrypt(user.Token, secretKey).toString(CryptoJS.enc.Utf8);
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;    
  }