import axiosInstance from './axios.js';
import {getLocalStorage} from './storage';
import Api from './Api.js'; 
import { toast } from 'react-toastify';

let token= {token: getLocalStorage("acessToken"),expiration: getLocalStorage("expiration")};

export const post=async(url,data)=>{
    const result=   await Api.request('post',url,data);
 
    return result;
 
 }
 
 export const put=async (url,data)=>{
     const result=   await Api.request('put',url,data);
 
    return result;
 }
 
 export const get=async (url)=>{
 
     const result=   await Api.request('get',url);
 
     return result;
 }
 
 export const del=async (url)=>{
 
     const result=   await Api.request('delete',url);
 
     return result;
  }

// export const post=async(url,data)=>{
   
//     if(validarAcesso()){
//    const result=   await  axiosInstance.post(url,data)
//         .then((response)=>{
          
//          if(response.data != null){
//              return response.data; 
//             }else {
//                 return null;
//             }
//         })
//         .catch(error =>{
//             console.log(error)
//             return null;
//     })

//     return result
//    }
//    return null;
// }

// export const put=async (url,data)=>{

//     if(validarAcesso()){
//        await axiosInstance.put(url,data)
//         .then((response)=>{
//        if(response.data != null){
//         console.log(response.data);
//             return JSON.parse(response.data) 
//        }
//     })
//         .catch(error =>{
              
//         console.log(error.response);
//         return null;
//      })
//     }
//     return null;
// }

// export const get=async(url)=>{

//     if(validarAcesso()){
//         console.log(url)
//         return await axiosInstance.get(url)
//         .then((response)=>{
//        if(response.data != null){
        
//         return response.data;
//        }else{
//            return null;
//        }
//     })
//     .catch(error =>{
//         console.log(error.response);
//     })
//     }
//     return null;
// }

// export const del=async(url)=>{

//    if(validarAcesso()){

//     return await axiosInstance.delete(url)
//     .then((response)=>{
//        if(response.data != null){
        
//          return response.data;
//          }else{
//            return null;
//          }
//         })
//         .catch(error =>{
//         console.log(error.response);
//         })
//    }
//         return null;
//  }



 