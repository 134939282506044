import './Header.css'
import React from 'react'
import { useHistory } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { authKey } from '../../global';
import { isAuthenticated } from '../../store/config/auth';
import { user } from '../../store/config/dadosuser';

export default function Header(props){
  const history = useHistory();

  function logout(){
    localStorage.clear(authKey);
    history.push("/");
    window.location.reload();
  }

  return (
          <header className="header d-none d-sm-flex flex-column">
            <div className="item">
              <h1 className="mt-3">
                  <i className={`fa fa-${props.icon}`}></i> {props.title}
              </h1>
            
              <p className="lead text-muted">{props.subtitle}</p>
              {isAuthenticated() ? (<Dropdown id="drop">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {user.nome}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/">Home</Dropdown.Item>
                    <Dropdown.Item onClick={()=>logout()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>) 
              : 
              null
              }

          </div>
              
        </header>

  );
}
