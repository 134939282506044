import React,{useState,useEffect} from "react";
import Main from "../../components/template/Main";
import {FiX,FiEdit2,FiLink} from 'react-icons/fi';
import {post} from '../../store/config/register';
import { useHistory } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css'
import './Documentos.css'

const headerProps = {
  icon: 'list-alt',
  title: 'Registo de Documentos'
}

export default function Documentos() {
    const history = useHistory(); 
  

    useEffect(()=>{
      buscaDocumentos();
    },[])

 async function buscaDocumentos() {
      const esp = await post('/Espaco/v1/Dados');
      if(esp !== undefined) {

    } 
  }

  function pesquisaAtas() {
    alert ('Vai aparecer na grid os documentos')
    }

  function pesquisaComunicados() {
    alert ('Vai aparecer na grid os comunicados')
  }

  function pesquisaConvencao () {
    alert ('Vai aparecer na grid as convenções')
  }

  function pesquisaEditais () {
    alert ('Vai aparecer na grid os editais')
  }

  function sair() {
    history.push('/Menu1')
  }

    function renderForm(){

        return(
            <div className="telaPrincipal">
                <div className="row1">
                    <button className="btnAtas" id="btnDoc1" onClick={() => {pesquisaAtas()}}>
                      Atas de Assembléias
                    </button>

                    <button className="btnComunicados" id="btnDoc1" onClick={() => {pesquisaComunicados()}}>
                      Comunicados
                    </button>

                    <button className="btnConvencao" id="btnDoc1" onClick={() => {pesquisaConvencao()}}>
                      Convenção e Regimento
                    </button>

                    <button className="btnEditais" id="btnDoc1" onClick={() => {pesquisaEditais()}}>
                      Editais de Convocação
                    </button>

                    <button className="btnSairDoc" id="btnSairDoc" onClick={() => {sair()}}>
                      Sair
                    </button>

                </div>



                <div className='tabelaDoc'>
                    <table>
                      <thead>
                        <tr>
                          <th className="tabelaNome">Nome</th>
                          <th scope="col" className="colData">Data</th>
                        </tr>
                      </thead>

                      <tbody>
                              <tr>
                                <td data-label="Nome">Ata de Junho de 2023</td>
                                <td  data-label="Data">01/06/2023</td>

                                <td>
                                  <button className="action" id="btnDoc" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Documento" >
                                  <FiX color="#FFF" size={17} />
                                  </button>


                                  <button id="btnCarregar" title="Upload" className="fa fa-upload"></button>


                                </td>
                              </tr>


                      </tbody>
                    </table>

                  </div>
            </div>
        );
    }
    return(
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );

}