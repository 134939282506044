import React,{useState} from "react";
import {FiX,FiUserCheck} from 'react-icons/fi';
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";

import './PesquisaMorador.css';
import { get } from "../../store/config/register";

export default function PesquisaMorador({conteudo, close}){
    const [pesquisa,setPesquisa]=useState('');
    const [moradores,setMoradores]=useState([]);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(moradores, page, 10);

    function  updateField( event){
        setPesquisa(event.target.value );
    }

   async function buscaMorador(pesquisa) {
      let mor = await get('/Pessoa/v1/Morador?nome='+pesquisa+'&empresa_id='+conteudo.empresaId);

      if(mor !== undefined) {
         setMoradores(mor);
      }
    }

    function renderForm(){
        return (
          <div className="modalPesquisa">
            <div className="containerPesquisa">
          <button className="closePesquisa" onClick={ close }>
            <FiX size={23} color="#FFF" />
            Voltar
          </button>
    
          <div>
            <h2>Pesquisa de Moradores</h2>
    
            <div className="row1">
              <span>
              Morador: 
              </span>
              <div className="input-group mb-3">
                    <input type="text" class="form-control" name="pesquisa"  value={pesquisa} placeholder="Pesquise um morador" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={e => updateField(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-info" type="button"  onClick={() => buscaMorador(pesquisa)}>Pesquisar</button>
                        </div>
                    </div>
            </div>
      
          </div>
          {renderTable()}
        </div>
       
      </div>
        );
        
      }

      function renderTable(){
        return(    
      <div className='tabela'>
          <hr/>
          <table>
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Unidade</th>
                <th scope="col">#</th>
              </tr>
            </thead>
              <tbody>
                {slice === undefined ? null :slice.map((item, index)=>{
                  return(
                    <tr key={index}>
                      <td data-label="Nome">{item.nome}</td>
                      <td data-label="Unidade">{item.unidade}</td>
                      <td data-label="#">
                        <button className="action" style={{backgroundColor: '#90EE90' }} onClick={() => close(item)} >
                        <FiUserCheck color="#FFF" size={17} />
                        </button>
                      </td>
                    </tr>
                  )     
                })}
              </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
      </div>
        );
      }

    return (
        <div>
            {renderForm()}
        </div>
    );
}