import React from "react";
import {FiX, FiUpload} from 'react-icons/fi';
import './ModalInstallIOS.css'
import { Label } from "@material-ui/icons";
import { green, grey } from "@material-ui/core/colors";

const logo= require('../../assets/imgs/logoApp192.png')

export default function ModalInstallIOS({conteudo,close}) {
    return (
        <div>
             <div className="modalIOS">
                <div className="container">
                    <div className="close">
                        <button className="closeIOS" onClick={() => close()}>
                        <label><FiX size={23} color="#000"  /></label>
                        </button>
                        
                    </div>
                    <div className="logo">                   
                        <img src= {logo}/>
                        <label className="nomeApp">Aplicativo La Villette</label>
                        <label style={{color: 'grey'}}>Adicione esse aplicativo à sua tela inicial para fácil acesso e uma melhor experiência.</label>
                    </div>      
                    <label style={{fontWeight: 'bold'}}>Para instalar clique no icone <FiUpload size={23}  /> do seu iPhone, em seguida escolha a opção: "Adicionar à Tela de Início", após isso clique em "Adicionar" que está no canto superior direito.</label> 
                </div>
                   
            </div>
        </div>
    );
}