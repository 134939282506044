import axios from 'axios';

import { baseApiUrl,userKey} from '../../global';

class Api {
  constructor() {
    this.token = localStorage.getItem('accessToken');
    this.baseUrl = baseApiUrl;
    this.timeout = 30000;
  }

  async request(method, url, data = null, headers = {}) {
    try {
      const response = await axios({
        method,
        url: `${this.baseUrl}${url}`,
        data,
        headers: {
          Authorization: `Bearer ${this.token}`,
          ...headers
        },
        timeout: this.timeout
      });

     
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await this.refreshToken();
        const response = await axios({
          method,
          url: `${this.baseUrl}${url}`,
          data,
          headers: {
            Authorization: `Bearer ${this.token}`,
            ...headers
          },
          timeout: this.timeout
        });
        return response.data;
      }if(error.response && error.response.status === 404){
        return null;
      }else {
        throw error;
      }
    }
  }

  async  refreshToken() {
    const response = await axios.post(`${this.baseUrl}/Autenticacao/v1`,JSON.parse(userKey) );
    this.token = response.data.acessToken;
    const expirationDate = new Date(Date.now() + 1200 * 10); // Token expira em 1 hora
    localStorage.setItem('accessToken', this.token);
    localStorage.setItem('expirationDate', expirationDate.toISOString());

  }

 }

export default new Api();