import React,{useState} from "react";
import Main from "../../components/template/Main";
import { put } from "../../store/config/register";
import { useParams } from "react-router-dom";
import { Buffer } from 'buffer';
import { parse } from "date-fns";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";


import {VscEye,VscEyeClosed} from 'react-icons/vsc'

import './NovaSenha.css';

const initialState ={
    usuario: {
      Id: 0,
      Empresa_Id: 0,
      Senha: '',
      ConfirmarSenha: ''
    }
  }

  const headerProps = {
    icon: "key",
    title: "Nova Senha",
    subtitle: "Nova Senha"
}

export default function NovaSenha(){
    const history = useHistory();
    const [usuario,setUsuario] = useState(initialState.usuario);
    const [error,setError] = useState('');
    const convert = (from, to) => str => Buffer.from(str, from).toString(to)
    const hexToUtf8 = convert('hex', 'utf8')
    const {id}=useParams(); 

    const [eyeIsClosedSenha,setEyeStateSenha]=useState(false);
    const [eyeIsClosedConfirmar,setEyeStateConfirmar]=useState(false);



    const empresa=hexToUtf8(id);

    const idEmpresa =empresa.split('/')[0];
    const data =parse(empresa.split('/')[1],"yyyy-MM-dd'T'HH:mm:ss",new Date());

    function  updateField(event) {
        const usu ={...usuario}
          
        usu[event.target.name] =event.target.value
        setUsuario(usu);
    }

   

    function valida(senha) {
       // let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z])(?!\1)|[!@#$%;*(){}_+^&]){6,}$/;
        let regex =/(?=.*[}{,.:;()^@#!$%?&~=+\-_*\-+.])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}/;
        if (regex.test(senha) === true) {
           return true;
        } else {
            return false;
        }
    }

    async function Validar() {
        if(usuario.ConfirmarSenha !== usuario.Senha) {
            setError('A confirmação da senha deve ser igual a senha.');
            return false;
        } else {
            if(usuario.Senha.length < 6) {
                setError('a senha deve ter 6 caracteres');
                return false;
            } else {
                if(valida(usuario.Senha) === true) {
                    return true;
                }else {
                    setError('A senha deve conter letras, números e caracteres especiais');
                    return false;
                }
            }
        }
    }

  async  function AtualizarSenha(){
        if(Validar()){
            const data={senha: usuario.Senha};
            await put(`/Login/v1/AlteraSenha/${idEmpresa}`,data);
            await toast.success('senha atualizada com sucesso');
            history.push('/Auth');
        }
    }

    function changeTypeSenha(){
        setEyeStateSenha(!eyeIsClosedSenha);
    }

    function changeTypeConfirma(){
        setEyeStateConfirmar(!eyeIsClosedConfirmar);
    }
    
    function renderForm(){
        return (<div>
                 {data > new Date() ? ( 
                    <div>
                    <div className="email">
                        <div className="form-group">
                            < label>Nova senha:</label>
                            <div class="input-group mb-3">
                            <input name="Senha"  value={usuario.Senha}  type= {!eyeIsClosedSenha ? "password" :"text"} class="form-control" placeholder="Senha" onChange={(e) =>updateField(e)} />
                            <div class="input-group-append">
                            <button className='buttonVisibleC'  type="button" color='#fff' onClick={changeTypeSenha}>{eyeIsClosedSenha ? <VscEyeClosed color='#808080' /> :<VscEye color='#808080'/>}</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="email">
                        <div className="form-group">
                            <label>Confirmar Senha:</label>
                            <div class="input-group mb-3">
                            <input name="ConfirmarSenha"  value={usuario.ConfirmarSenha}  type= {!eyeIsClosedConfirmar ? "password" :"text"} class="form-control" placeholder="Confirmar Senha" onChange={(e) =>updateField(e)} />
                            <div class="input-group-append">
                            <button className='buttonVisibleC'  type="button" color='#fff' onClick={changeTypeConfirma}>{eyeIsClosedConfirmar ? <VscEyeClosed color='#808080' /> :<VscEye color='#808080'/>}</button>
                            </div>
                    </div>
                        </div>
                    </div>
                    <label style={{color: '#FF0000'}}>{error}</label>
                    <label style={{fontWeight: 'bold'}}>*A senha deve conter no minímo 6 caracteres entre letras, números e caracteres especiais</label>
                    <hr/>
            <div id="botao">
                  <button  className="btn btn-primary buttonRedefinir" onClick={async (e) => await AtualizarSenha()}>
                    Atualizar Senha
                  </button>
            </div>
             </div>) : 
             (<div className="expirado">
                <h2>A Requisição expirou! Clique no botão para fazer uma nova requisição</h2>
                <br/>
                <div id="botao">
                  <button className="btn btn-primary" onClick={async (e) =>history.push('/Usuario/EsqueciSenha') }>
                    Redefinir Senha
                  </button>
            </div>
                </div>)}
        </div>
        );

      
    }

    return (
          <Main {...headerProps}>
          {renderForm()}
          </Main>  
        );
}