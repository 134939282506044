import React, {useState,useEffect} from "react";
import Main from "../../components/template/Main";
import {FiX,FiEdit2} from 'react-icons/fi';
import { useParams } from "react-router-dom";
import { format,parse } from "date-fns";
import { toast } from "react-toastify";
import {post,put,get,del} from '../../store/config/register';


const headerProps = {
    icon: "group",
    title: "Lista de Convidados",
  }

  const initialState ={
    convidado: {
      id: 0,
      agenda_Id: 0,
      nome:'',
      acompanhantes: 0
    }
  }

export default function ReservaConvidado(){
    const {idAgenda} = useParams();
    const [convidado,setConvidado] =useState(initialState.convidado);
    const [convidados,setConvidados] =  useState([]);
    const [agenda,setAgenda]= useState({});
    

    useEffect(()=>{
      buscaConvidados();
      buscaAgenda();
    },[])

    function  updateField( event){
      const conv ={...convidado}
      if(event.target.name === 'acompanhantes'){
        conv[event.target.name] = parseInt(event.target.value) 
      }else {
        conv[event.target.name] =event.target.value
      }
      
      setConvidado(conv);
  }

    async function buscaAgenda(){
      const agend =await get('/EspacoAgenda/v1/'+ idAgenda);
      if(agend !== undefined){
        setAgenda(agend);
      }
      
    }
    async function buscaConvidados(){
      const convs =await get('/EspacoAgendaConvidado/v1/'+ idAgenda)
      if(convs !== undefined) {
        setConvidados(convs);
      }
    }

    async function deleteConvidado(Id) {
      await del('/EspacoAgendaConvidado/v1/'+Id+'/' +idAgenda)
      await toast.success('Convidado apagado.')
      await buscaConvidados()
    }

   async function save() {
        setConvidado({...convidado,agenda_id: idAgenda})
        convidado.agenda_id =parseInt(idAgenda);
        if(Validar()) {
          if(convidado.id === 0) {
            await post('/EspacoAgendaConvidado/v1', convidado)

            await toast.success('Convidado adicionado a lista')
          }else {
            await put('/EspacoAgendaConvidado/v1/'+ idAgenda, convidado)

            await toast.success('Convidado modificado.')
          }
         

         await buscaConvidados();

         clear();
        }

    }

    function Validar(){
      if(convidado.nome === ''){
        toast.warning('Digite o nome do convidado');

        return false;
      }

      return true;
    }

    function clear(){
        setConvidado(initialState.convidado)
    }

    async function Edit(Id){
    const conv =  await get('/EspacoAgendaConvidado/v1?Id='+Id+'&IdAgenda='+ idAgenda);
    console.log(conv);
    if(conv !== undefined) {
      setConvidado({...conv});
    }
      }

    function renderTable(){
        return(
            <div className='tabela'>
            <table>
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Acompanhante</th>
                  <th scope="col"></th>
                </tr>
              </thead>
                <tbody>
                  {convidados === undefined ? null :convidados.map((item, index)=>{
                    return(
                      <tr key={index} >
                        <td data-label="Nome">{item.nome}</td>
                        <td data-label="Acompanhante">{item.acompanhantes}</td>
                        <td  data-label="">
                        <button className="action" style={{backgroundColor: '#F99E08' ,marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={(e) => {Edit(item.id)}} >
                          <FiEdit2 color="#FFF" size={17} />
                          </button>
                          <button className="action" style={{backgroundColor: '#ff0000', marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Convidado"  onClick={async(e) => await deleteConvidado(item.id)} >
                          <FiX color="#FFF" size={17} />
                          </button>
                          </td>
                      </tr>
                    )     
                  })}
                </tbody>
              </table>
        </div>
        );
    }

    function renderForm(){
        return (
            <div className="form">
              <div>
                <label><strong>{agenda.descricao} {agenda.data === undefined ?'':format(parse(agenda.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy HH:mm')}</strong></label> 
              </div>
                <div className="nome-visitante">
                <div className="form-group">
                  <label>Nome:</label>
                  <input type="text" name="nome" 
                  placeholder="Nome" 
                    className="form-control" value={convidado.nome} 
                    onChange={e => updateField(e)} />
                </div>
            </div> 
            <div className="nome-visitante">
                <div className="form-group">
                  <label>Acompanhante:</label>
                  <input type="number" name="acompanhantes" 
                  placeholder="0" 
                  min={0}
                    className="form-control" value={convidado.acompanhantes} 
                    onChange={e => updateField(e)} />
                </div>
            </div> 
            <hr/>           
            <div id="botao">
                  <button  className="btn btn-primary ml-2" onClick={ async e =>  await save()}>
                    Salvar
                  </button>
                  <button className="btn btn-secondary ml-2" onClick={e => clear()}>
                    Cancelar
                  </button>
            </div>

            <hr />
            {renderTable()}
          </div>
        );
    }

    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}