import { authKey } from "../../global";
import {getLocalStorage} from './storage';
import { user } from "./dadosuser"; 

export const isAuthenticated = () => {

    if(user){
         return true;
     }else{
         return false;
    }
    
};

export const isFreeRoute = () => {

       if(user){
            return true;
        }else{
            return false;
       }
       
   };

export const isAdmin = () => {

     
     if(user) {
        if (user.Admin) {
            return true;
        }else {
            return false;
        }
    }else {
        return false;
    }
}