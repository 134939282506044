import React from "react";
import Main from "../../components/template/Main";
import ButtonImage from "../../components/template/ButtonImage";

const headerProps = {
    icon: "tasks",
    title: "Regras de Espaço",
  }

export default function Regras(){

    const piscina = require('../../assets/imgs/piscina_80x80.png');
    const churrasqueira = require('../../assets/imgs/churrasqueira_80x80.png');
    const academia = require('../../assets/imgs/esteira_80x80.png');

    function renderForm(){
        return (
            <div id="menu-div1">
                 <div className='menus form'>
                    <ButtonImage  conteudo={{nome: "Piscina", color:"#5499C7",icon: 'comments',link: 'https://condominio.cisuporte.com.br/regimento/lavi_piscinas_saunas.html', image: piscina}}/>
                    <ButtonImage  conteudo={{nome: "Churrasqueira", color:"red",icon: 'comments',link: 'https://condominio.cisuporte.com.br/regimento/lavi_churrasqueira.html', image: churrasqueira}}/>
                    <ButtonImage  conteudo={{nome: "Academia", color:"green",icon: 'comments',link: 'https://condominio.cisuporte.com.br/regimento/lavi_academia.html', image: academia}}/>
                 </div>
            </div>
        );
    }
    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}