import React, { useState } from "react";
import { secretKey} from "../../global";
import { Buffer } from 'buffer';
import { post } from "../../store/config/register";
import { toast } from 'react-toastify';


export default function Teste() {


function encrypt(valueStringHex, keyStringHex) {

  //console.log(valueStringHex,keyStringHex)

  const CryptoJS = require('crypto-js');
  const value =  CryptoJS.enc.Hex.parse(valueStringHex);
  const key  = CryptoJS.enc.Hex.parse(keyStringHex);
  const ivvar   = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
  const encryptedStringHex = CryptoJS.AES.encrypt(value, key, {iv: ivvar, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.NoPadding}).ciphertext.toString();
  setDecifra(encryptedStringHex);
  console.log(encryptedStringHex)
  return encryptedStringHex;
  }

  function decrypt(valueStringHex, keyStringHex) {

     console.log(valueStringHex,keyStringHex)
    const CryptoJS = require('crypto-js');
    const value = CryptoJS.enc.Hex.parse(valueStringHex);
    const key = CryptoJS.enc.Hex.parse(keyStringHex);
    const ivvar   = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
    const decryptedStringHex = CryptoJS.AES.decrypt({ciphertext: value}, key, {iv: ivvar, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.NoPadding});
    setDecifra(decryptedStringHex.toString());
    return decryptedStringHex.toString();
    }

const [valor,setValor]=useState('');
const [decifra,setDecifra]=useState('');
const hex = require('string-hex');

// function cifra(valor){

//   let bytes  = CryptoJS.AES.encrypt(valor, secretKey).toString();
  
//   setDecifra(bytes);
// }

// function decifrar(valor){

//   let bytes  = CryptoJS.AES.decrypt(valor, secretKey).toString(CryptoJS.enc.Utf8);
//   let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
//   setDecifra(decryptedData);
// }


function teste(buffer) {
  let buf =Buffer.from(buffer, "hex")
 let data = buf.toString("utf8"); 
 console.log(data);
}

const convert = (from, to) => str => Buffer.from(str, from).toString(to)
const utf8ToHex = convert('utf8', 'hex')
const hexToUtf8 = convert('hex', 'utf8')
return (
  <div>
  <input value={valor} onChange={(e) =>setValor(e.target.value)}/>
  <input value={decifra} onChange={(e) =>setDecifra(e.target.value)}/>
  <button onClick={async(e)=> enviarEmail(0)}>Cifra</button>
  <button onClick={()=>console.log(hexToUtf8(valor)) }>Decifra</button>
</div>
);

async function enviarEmail(Id){
  let emails =[];
   emails.push("angelitamouraheil@gmail.com");     

   let data =  { sender:emails, subject: 'Email de confirmação de cadastro',html: template(),empresa_id: 1}
   console.log(JSON.stringify(data));
   let result =await post('/Email/v1',data);
   await toast.success('email enviado com sucesso');
}

function template(usuario,Id){
  let temp = `<html>
  <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <title>Confirmação de Cadastro - Aplicativo do Condomínio - La Villete</title>
  </head>
  <body>
      <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
          <center>
              <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
                  <tr>
                      <td>
                          <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
                          </table>
  
                          <p>&nbsp;</p>
  
                          <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
                              <tr>
                                  <td>
                                      <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
                                          <tbody>
                                              <tr>
                                                  <td style="padding:0px">
                                                      <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                                                          <img alt="Condomínio La Villete" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
                                                          style="display:block">
                                                      </a></center>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
  
                              <tr>
                                  <td>
                                      <h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
                                      Olá ${"Angelita"},</h1>
  
                                      <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                                          seja bem-vindo(a) ao Aplicativo do Condomínio
                                      </h2>
  
                                      <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                                          Você está recebendo este e-mail para <b>confirmar o seu cadastro no Aplicativo do Condomínio</b>.<br>
                                      </p>
  
                                      <table width="320" border="0" cellpadding="0" cellspacing="0" align="center">
                                          <tr bgcolor="#2A4C1D">
                                              <td style="display:table-cell;font-size:12px;font-family:Arial,sans-serif; 
                                                      text-align:center; line-height:100%; text-transform:uppercase; 
                                                      padding:15px 0px;color:#ffffff">
                                                  
                                                  <a href="${"https://condominio.cisuporte.com.br/Confirmar"+"/"+utf8ToHex("angelitamouraheil@gmail.com"+"/"+"40")}" 
                                                  style="text-decoration:none;font-family:Raleway,sans-serif;font-weight:500;
                                                  color:#ffffff" target="_blank" rel="noreferrer">CONFIRME AQUI SEU CADASTRO</a>
                                              </td>
                                          </tr>
                                      </table>
                                      <br>
                                      <br>
                                      <br>
                                      <center> <i>Caso você não tenha solicitado o cadastro, favor desconsiderar este e-mail. </i>
                                      </center>
                                      <!--p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                                          <i>Caso você não tenha solicitado o cadastro, favor desconsiderar este e-mail. </i>
                                      </p-->
                                  </td>
                              </tr>
  
                              <tr style="margin-top:25px">
                                  <td>
                                      <div style="margin-top:25px">
                                      </div>
                                  </td>
                              </tr>
                              
                              <tr>
                                  <table align="center" border="0" cellpadding="0" cellspacing="0" style="background-color:whitesmoke" width="640">
                                      <tbody>
                                          <tr>
                                              <td width="390" heigth="96">
                                                  <a href="https://pt-br.facebook.com/condominiolavillette/" 
                                                  target="_blank" rel="noreferrer">
                                                  <img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette.png" 
                                                  style="display:inline-block;float:left;width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px">
                                              </a></td>
                                              <td width="58" heigth="96"><img alt="" src="https://condominio.cisuporte.com.br/imagens/vazio.png" 
                                                  style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px"></td>
                                              <td width="58" heigth="96"><img alt="" src="https://condominio.cisuporte.com.br/imagens/vazio.png" 
                                              style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px"></td>
                                              <td width="58" heigth="96">
                                                  <a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                                                  <img alt="Facebook" src="https://condominio.cisuporte.com.br/imagens/facebook.png" 
                                                  style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px">
                                                  </a>
                                              </td>
                                              <td width="58" heigth="96"><img alt="" src="https://condominio.cisuporte.com.br/imagens/vazio.png" 
                                              style="display:inline-block;float:left; width:100%;height:auto;border:0px;padding:0px 0px;margin:0px 0px"></td>
                                          </tr>
                                          <tr>
                                              <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
                                              </table>
                                              <p>&nbsp;</p>
                                          </tr>
  
                                      </tbody>
                                  </table>
                              </tr>
  
                          
                          </table>
                      </td>
                  </tr>
                  
                  <tr>
                      <td>
                          <center>
                          <p>
                              <a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br</a>
  
                              <!--br>
                                  
                                  Se deseja não receber mais mensagens como esta, clique aqui.
                              -->   
                          </p>
                      </td>
                  </tr>
                  
              </table>
          </center>
      </div>
      
      
  </body>
  </html>`;

  return temp;
}


  
}