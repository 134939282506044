import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import { login_Id } from "../../store/config/dadosuser";
import {get} from '../../store/config/register';

export default function ButtonMenu({conteudo}) {
    const history = useHistory();
    const [contador,setContador]=useState(false);
    const [naoLido,setNaoLido] =useState(conteudo.contador)
    let opacity =conteudo.color === "#808080" ? 0.3 : 1;

    async function NaoLido() {
        const  lid =await get('/AvisoPessoa/v1/NaoLido/'+login_Id);
        if(lid){
            setContador(true);
        }
        setNaoLido(lid)
      }

      if(naoLido ){
        NaoLido();
      }
    

  
    return (
        <div className='menu' style={{backgroundColor: conteudo.color,opacity: opacity}}>
           {contador === true  && (
           
              <div className="contador">
                 {console.log('passou aqui')}
                <label>{naoLido}</label>
              </div>
            )
           }
            <span style={contador === true ? {marginTop: -25}: null}>
                <i className={"fa fa-"+conteudo.icon} onClick={() =>history.push(conteudo.link)}></i>
            </span>


            <div className='link'>
                <span>
                    <i className={"fa fa-"+conteudo.icon} onClick={() => history.push(conteudo.link)}></i>&nbsp;
                    {conteudo.nome} 
                </span>

            </div>
       </div>
    );
}