import React, { useState ,useEffect} from "react";
import Main from "../../components/template/Main";
import {FiX} from 'react-icons/fi';
import { Button } from "react-bootstrap";
import { Buffer } from 'buffer';
import { post } from "../../store/config/register";
import { toast } from 'react-toastify';
import { authKey,secretKey } from "../../global";
import CryptoJS from "crypto-js";
import { getLocalStorage } from "../../store/config/storage";
import './EsqueciSenha.css';
import { Link,useHistory } from "react-router-dom";
import { format } from 'date-fns';

const headerProps = {
    icon: "key",
    title: "Esqueci a Senha",
    subtitle: "Esqueci a Senha"
}

export default function EsqueciSenha(){
    const history = useHistory();
    const usuario =JSON.parse(getLocalStorage("empresa"));
   const [email,setEmail]=useState('');
   const [error,setError] = useState('');
   const [emailEnviado,setEmailEnviado]=useState(false);
   const [user,setUser]= useState({})
   const link =window.location.href.replace("/EsqueciSenha","/NovaSenha");
   const [empresa_Id,setEmpresa_Id]= useState(0);

   function Dados(){
    let bytes  = CryptoJS.AES.decrypt(usuario.empresa, secretKey).toString(CryptoJS.enc.Utf8);
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    if(decryptedData!== ''){
        setEmpresa_Id(parseInt(decryptedData));
    }
   
   }

   useEffect(()=>{

    Dados();
   },[empresa_Id])

    function renderForm(){
        return(
                <div className="pass-content">
                    {emailEnviado === false?(<div className="pass-modal">
                   
                   <label style={{fontWeight: 'bold'}}>Esqueci Senha</label>
                 
                   <input className="inputRedefinir" value={email} name="email" placeholder="E-mail" type="text" onChange={(e) =>setEmail(e.target.value)}></input>
                   <Button className=".buttonRedefinir" onClick={async()=> RedefinirSenha()} >Redefinir Senha</Button>
                   <div className="retornalogin">
                    <br />
                   <Link to="/Auth" style={{fontWeight: 'bold'}}>Faça login</Link>
                   <label style={{color: '#FF0000'}}>{error}</label>
                   </div>    
                   </div>)
                   :
                   (
                    
                   <div className="redefinicao">
                    <button  style={{backgroundColor: '#000', color: '#FFF', borderRadius: '6px', with:'60px',height: "35px"}} onClick={(e) => history.push('/')} >
                        <FiX color="#FFF" size={15} style={{marginRight: '10px'}}/>
                    </button>
                    <label > Um email foi enviado para redefinição de senha. Entre no seu email e clique no link para redefinir sua senha. Este email tem validade de 30 minutos. Caso a redefinição de senha não seja feita neste tempo, refaça a solitação.</label>
                   </div>)}
                    
                </div>
        );
    }

   async function ValidaEmail(){
    const data ={email: email};
 
    const resp = await post('/Login/v1/Validar',data)
    if(resp === ''){
        setError('E-mail não está cadastrado no APP!');
        return {existe:false};
       }else{

        setUser(resp);
        return {existe:true, user: resp};
       }

    }

   async function RedefinirSenha(){
    let result=   await ValidaEmail();

    if(result.existe){
        enviarEmail(result.user);
        setEmailEnviado(true);
    }
    }

    async function enviarEmail(user){
        let emails =[];
         emails.push(email);
      
         let data =  { sender:emails, subject: 'Modificação de senha',html: template(user),empresa_id: empresa_Id}
         let result =await post('/Email/v1',data);
         await toast.success('email enviado com sucesso');
      }

      function template(usuario){
       let temp=`<html>
       <head>
       <meta charset="utf-8">
       <meta name="viewport" content="width=device-width, initial-scale=1">
       <title>Modificação de Senha - Aplicativo do Condomínio - La Villete</title>
       </head>
       
        <body>
        <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
           <center>
           
               <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
                   <tr>
                       <td>
                           <table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
                           </table>

                           <p>&nbsp;</p>
                           
                            <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
                                <tr>
                                    <td>
                                        <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
                                            <tbody>
                                                <tr>
                                                    <td style="padding:0px">
                                                        <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
                                                            <img alt="Condomínio La Villette" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
                                                            style="display:block">
                                                        </a></center>
                                                    </td>
                                                </tr>
                                            </tbody>
                                       </table>
                                   </td>
                               </tr>
                                       
                               <tr>
                                   <td>								
                                       <h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
                                        Olá ${usuario.nome},</h1>
                                        <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
                                         ESQUECEU SUA SENHA?
                                        </h2>
                                         <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
                                                    Você está recebendo este e-mail para <b>modificar sua senha no Aplicativo do Condomínio</b>.<br>
                                              </p>
                                         <table width="320" border="0" cellpadding="0" cellspacing="0" align="center">
                                                   <tr bgcolor="#2A4C1D">
                                                   <td style="display:table-cell;font-size:12px;font-family:Arial,sans-serif; 
                                                       text-align:center; line-height:100%; text-transform:uppercase; 
                                                       padding:15px 0px;color:#ffffff">
                   
                                                       <a href="${link+"/"+utf8ToHex(usuario.id+"/"+format(Date.now() + 30*60000,"yyyy-MM-dd'T'HH:mm:ss"))}" 
                                                       style="text-decoration:none;font-family:Raleway,sans-serif;font-weight:500;
                                                       color:#ffffff" target="_blank" rel="noreferrer">ATUALIZE A SENHA</a>
                                                   </td>
                                                   </tr>
                                           </table>
                                          <br>
                                            <br>
                                            <center> <i>Caso você não tenha solicitado o cadastro, favor desconsiderar este e-mail. </i>
                                   </td>
                               </tr>
                               
                               <tr style="margin-top:25px">
                                   <td>
                                       <div style="margin-top:25px">
                                       </div>
                                   </td>
                               </tr>
                               <tr>
                               <td>
                                   <a href="https://pt-br.facebook.com/condominiolavillette/" 
                                       target="_blank" rel="noreferrer">
                                       <img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette_640x96.png" width="100%" height="100%">
                                   </a>
                               </td> 
                               </tr>
                           </table>
                       </td>
                   </tr>			
                 
               </table>
               <p>
                <a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br </a>
                </p>
           </center>
       </div>
   </body>
</html>`;

       return temp;
      }

      const convert = (from, to) => str => Buffer.from(str, from).toString(to)
    const utf8ToHex = convert('utf8', 'hex')

    return(
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}
