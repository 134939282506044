import React,{useState,useEffect} from "react";
import {FiX,FiCheck} from 'react-icons/fi';
import { get, put,post } from "../../store/config/register";
import { toast } from "react-toastify";
import './AutorizacaoAcesso.css';
import { format,parse } from "date-fns";
import { empresa_Id } from "../../store/config/dadosuser";
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";




export default  function AutorizacaoAcesso(){
    let bl =[];
    const [selectBlocos, setSelectBlocos] =useState([]);
    const [selectUnidades,setSelectUnidades]=useState([]);
    const [arrayUnidades,setArrayUnidades] = useState([]);
    const [usuarios,setUsuarios]=useState([]);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(usuarios, page, 10);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [blocos,setBlocos] =useState([]);
    const [unidades,setUnidades]= useState([]);
    const [moradores,setMoradores]=useState([]);
    const [anfitriaID,setAnfitriaoID]=useState(0);


   const handleEpandRow = async(event, userId,item) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) :  (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
          currentExpandedRows.filter(id => id !== userId) :
          currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);

    let array =[];
    selectBlocos.forEach((item)=>{
        array.push (unidades.filter((unid) => unid.bloco_Id === item)) ;
        setArrayUnidades(array);
    })

  }

  useEffect(()=>{
    montadados();
   
  },[selectUnidades])

  async function montadados(){
    let array =[];

    selectUnidades.forEach(async (item,index)=> {
       const resultado = await buscaMorador(item);
       array[index]=resultado;
       setMoradores(array);
    })
  }

  async function VerificaUsuario(){
 
    await getAutorizacao(empresa_Id);
}
  useEffect( () => {
    VerificaUsuario();
   
        },[])

      async function getAutorizacao(Empresa_Id) {
            const result = await get(`/Login/v1/NaoAutorizado/${Empresa_Id}`);
        setUsuarios(result);


        let un =[];
        result.map((item) => {
            un.push(item.unidade_Id);
            bl.push(item.bloco_Id);        
        })

        setSelectBlocos(bl);
        setSelectUnidades(un);
    }

    useEffect(()=>{
        buscaBloco(empresa_Id);
        buscaUnidade(empresa_Id);

    },[empresa_Id]);

    

    async function buscaBloco(empresa_id){
        const blocs = await   get(`/Bloco/v1/Empresa/${empresa_id}`);
        if(blocs != null){
            blocs.unshift({id:0, nome:""});
            setBlocos(blocs);
        }
    }

    async function buscaUnidade(empresa_id){
        const unids = await  get(`/Unidade/v1/Empresa/${empresa_id}`);
        if(unids != null) {
        //    unids.unshift({id:0, nome:""})
            setUnidades(unids);
        }
    }

    async function buscaMorador(unidade_id) {
        const mora = await get(`/Pessoa/v1/Unidade?unidade_Id=${unidade_id}&empresa_Id=${empresa_Id}`);
        return mora;
    }

    function renderSelectBloco(){    
        if(blocos !== undefined){
            return blocos.map(bloco =>{
                return (<option key={bloco.id} value={bloco.id}>{bloco.nome}</option>)
            })
        }        
    }

    function renderSelectUnidade( index){    
        if(arrayUnidades[index] !== undefined){
            return arrayUnidades[index].map(unidade =>{
                return (<option key={unidade.id} value={unidade.id}>{unidade.nome}</option>)
            })
        }        
    }

    function onChangeRadio(e) {
        setAnfitriaoID(parseInt(e.target.value));
    }

    function trocaBloco(e,index) {
        selectBlocos[index]=parseInt(e.target.value);

        let array =[];
        selectBlocos.forEach((item)=>{
            array.push (unidades.filter((unid) => unid.bloco_Id === item)) ;
            setArrayUnidades(array);
        })
    }

   async function trocaUnidade(e, index) {
        selectUnidades[index]=parseInt(e.target.value);
       const result =  await buscaMorador(parseInt(e.target.value));

       let array=[];

       
       moradores.map((item,ind)=>{
        if(ind === index){
            array.push(result);
        }else { 
            array.push(item);
        }       
        
       })

       setMoradores(array);

        }

    async function VincularUsuario (id,bloco_id,unidade_id){
        const data = { idreplicacao: anfitriaID, bloco_id: bloco_id,unidade_id: unidade_id}
        await put(`/Login/v1/IdReplicacao/${id}`,data);
         }

    async function AlteraStatus (id,bloco_id,unidade_id, item){
        if(anfitriaID !== 0){
            await  VincularUsuario(id,bloco_id,unidade_id);
            const data = { status: true}
            await put(`/Login/v1/AlteraStatus/${id}`,data);
            await  toast.success('Usuário ativado com sucesso');
            await enviarEmail(item);

        getAutorizacao();
       window.location.reload();
        }else {
            toast.warning("Selecione um morador");
        }
        
    } 

    async function enviarEmail(usuario){
        let emails =[];
       //  emails.push('william@cisistemas.com.br'); 
        emails.push(usuario.email);      

        let data =  { sender:emails, subject: 'Email de autorização do aplicativo',html: template(usuario),empresa_id: empresa_Id}
         //console.log(JSON.stringify(data));
         let result =await post('/Email/v1',data);
         await toast.success('email enviado com sucesso');
      }

      function template(usuario){
        let temp=`<html>
        <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Autorização de Acesso - Aplicativo do Condomínio - La Villete</title>
        </head>
		
         <body>
         <div bgcolor="#FFFFFF" marginwidth="0" marginheight="0">
			<center>
			
				<table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
					<tr>
						<td>
							<table cellpadding="0" cellspacing="0" border="0" style="font-family:Calibri,sans-serif" width="100%">
							</table>

							<p>&nbsp;</p>
							
							 <table width="640" border="0" cellpadding="0" cellspacing="0" align="center" bgcolor="#f5f5f5">
								 <tr>
									 <td>
										 <table align="center" cellpadding="0" cellspacing="0" style="font-family:Calibri,sans-serif" width="720">
											 <tbody>
												 <tr>
													 <td style="padding:0px">
														 <center><a href="https://pt-br.facebook.com/condominiolavillette/" target="_blank" rel="noreferrer">
															 <img alt="Condomínio La Villette" src="https://condominio.cisuporte.com.br/imagens/logolavillete.png" width="50%" height="50%" 
															 style="display:block">
														 </a></center>
													 </td>
												 </tr>
											 </tbody>
										</table>
									</td>
								</tr>
										
								<tr>
									<td>								
										<h1 style="display:block;font-size:18px;color:#363636;margin:26px 15px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;font-weight:400">
										 Olá ${usuario.nome},</h1>
										 <h2 style="display:block;font-size:18px;color:#363636;margin:0px 25px 0px;line-height:130%;font-family:Calibri,sans-serif;text-align:center;text-transform:uppercase;font-weight:900">
											 seja bem-vindo(a) ao Aplicativo do Condomínio
										 </h2>
										  <p style="display:block;font-size:16px;color:#363636;margin:22px 25px;line-height:130%;font-family:Calibri,sans-serif;text-align:center">
            										A administração do Condomínio La Villette autorizou o seu usuário para a utilização do APP de Moradores do Condomínio.<br>
         									  </p>  
									</td>
								</tr>
								
								<tr style="margin-top:25px">
									<td>
										<div style="margin-top:25px">
										</div>
									</td>
								</tr>
										
								<tr>
								<td>
									<a href="https://pt-br.facebook.com/condominiolavillette/" 
										target="_blank" rel="noreferrer">
										<img alt="La Villette"	src="https://condominio.cisuporte.com.br/imagens/rodape_emm_lavillette_640x96.png" width="100%" height="100%">
									</a>
								</td> 
								</tr>
							</table>
						</td>
					</tr>			
				</table>
                <p>
				<a href="https://acessoid.com.br/" target="_blank" rel="noreferrer"> Enviado por www.acessoid.com.br </a>
				</p>
			</center>
		</div>
	</body>
</html>`;
 
        return temp;
       }

    function render(){
        return (
            <div>
                 <hr/>
                 <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Email</th>
                              <th scope="col">Bloco</th>
                            <th scope="col">Unidade</th>
                            <th scope="col">#</th>
                        </tr>
                    </thead>
                   
                        {slice === undefined ? null : slice.map((item, index) => { 
              
                           
                               return(
                                <tbody>
                                <tr key={index}>
                                    <td data-label="Nome">{item.nome}</td>
                                    <td data-label="Email">{item.email}</td>
                                      <td data-label="Bloco">{item.bloco}</td>
                                    <td data-label="Unidade">{item.unidade}</td>                                                   
                                    <td data-label="#" className ="expand-button">
                                        <button className="action btn btn-success"  style={{marginLeft: "10px"}} onClick={(e) =>  handleEpandRow(e, item.id,item)} >
                                       { expandState[item.id] ?
                                        '' : 'Autorizar'}
                                        </button>
                                    </td>
                                </tr>
                                <>
                                {expandedRows.includes(item.id) ?
                                <tr className="body">
                                    
                                <div className="form">
                                <div className="nome-visitante">
                                    <div className="form-group">
                                    <label>Bloco:</label>
                                    <select  name="Bloco" 
                                        className="form-control"  
                                        value={selectBlocos[index]}  
                                        onChange={(e) => trocaBloco(e,index)} >{renderSelectBloco()} </select>
                                    </div>
                                </div> 

                                <div className="nome-visitante">
                                    <div className="form-group">
                                    <label>Unidade:</label>
                                    <select  name="Unidade" 
                                        className="form-control"  
                                        value={selectUnidades[index]}  
                                        onChange={(e) =>trocaUnidade(e,index)} >{renderSelectUnidade(index)} </select>
                                    </div>
                                </div>
                                   <label>Selecione o Morador</label>    
                                <hr/>
                                <table className="expansive">
                                <thead>
                                    <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Parentesco</th>
                                    <th scope="col">Nascimento</th>
                                    <th scope="col">#</th>
                                    </tr>
                                </thead>
                                {moradores[index] === undefined ? null : moradores[index].map((item,index2) => {
                        return (
                            <tbody>
                                <tr key={index2}>
                                <td data-label="Nome">{item.nome}</td>
                                <td data-label="Parentesco">{item.parente}</td>
                                <td data-label="Nascimento">{item.data_Nascimento === null ?'': format(parse(item.data_Nascimento,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy')}</td>
                                <td data-label="#">
                                <div class="radio">
                                    <label><input type="radio" value={item.id_Replicacao} onChange={(e)=> onChangeRadio(e)} name="optmorador"/></label>
                                </div>
                                </td>
                                </tr>
                            </tbody>
                        );
                    })}
                                </table>
                                <hr />
                                <div className="nova">
                                    <button className=" btn btn-success" onClick={async(e) =>  AlteraStatus(item.id,selectBlocos[index],selectUnidades[index],item)}>Autorizar</button>
                                </div>

                                </div>    
                                
                            </tr>
                            :null}
                                
                                </>                                
                            </tbody>
                            );
                        })}
                    
                 </table>
                 <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
            </div>
        );
    }
    return (
        <div>
            {render()}
        </div>
    );
}