import React, { useState,useEffect } from "react";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import './Editor.css'
import { useHistory,useParams} from "react-router-dom";
import {post, get, put} from '../../store/config/register';
import { toast } from 'react-toastify';


const copyStringToClipboard = function(str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  
  const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation"
  ];
  const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType"
  ];
  const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
      let optionElement = document.createElement("option");
      optionElement.setAttribute("class", "merge-field-select-option");
      optionElement.setAttribute("value", mergeFields[index]);
      optionElement.text = mergeFields[index];
      optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
  }
  const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "superscript",
    "subscript",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "link",
    "table",
    "|",
    "hr",
    "eraser",
    "copyformat",
    "|",
    "fullsize",
    "selectall",
    "print",
    "|",
    "source",
    "|",
    {
      name: "insertMergeField",
      tooltip: "Insert Merge Field",
      iconURL: "images/merge.png",
      popup: (editor, current, self, close) => {
        function onSelected(e) {
          let mergeField = e.target.value;
          if (mergeField) {
            editor.selection.insertNode(
              editor.create.inside.fromHTML("{{" + mergeField + "}}")
            );
          }
        }
        let divElement = editor.create.div("merge-field-popup");
  
        let labelElement = document.createElement("label");
        labelElement.setAttribute("class", "merge-field-label");
        labelElement.text = 'Merge field: ';
        divElement.appendChild(labelElement);
  
        let selectElement = document.createElement("select");
        selectElement.setAttribute("class", "merge-field-select");
        selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
        selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
        selectElement.onchange = onSelected;
        divElement.appendChild(selectElement);
  
      //  console.log(divElement);
        return divElement;
      }
    },
    {
      name: "copyContent",
      tooltip: "Copy HTML to Clipboard",
      iconURL: "images/copy.png",
      exec: function(editor) {
        let html = editor.value;
        copyStringToClipboard(html);
      }
    }
  ];
  
  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "pt_br",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true
    },
    width: 800,
    height: 842
  };

  const initialState={
    documento: {
      id: 0,
      espaco_Id: 0,
      tipoDocumento: 'R',
      texto: 0
    }
  }

 export default function Editor(){
    const [data, setData] = useState("");
    const [tipoDocumento,setTipoDocumento]=useState(initialState.documento.tipoDocumento);
    const [documento,setDocumento]=useState(initialState.documento);
    const {id}=useParams();

    useEffect(()=>{
      carregaParametro();
      if(id !== undefined) {
        CarregaDocumento(tipoDocumento);
      }
      
    },[])

    function carregaParametro() {
      if(id !== undefined) {
        setDocumento({...documento, espaco_Id: parseInt(id)})
      }
    }

   async function CarregaDocumento(tipo){
    const docs =await get('/EspacoDocumento/v1?IdEspaco='+id+'&Tipo='+tipo)
    if(docs !== ""){
      setData(docs.texto);
      setDocumento({...docs})
    } else {
      setData("");
      setDocumento(initialState.documento)
    }

    }

  async  function salvar() {
        await CarregaDocumento(tipoDocumento);
      documento.tipoDocumento=tipoDocumento;
      documento.texto =data;
      documento.espaco_Id = parseInt(id);
       // console.log(JSON.stringify(documento))
        if(documento.id === 0 ){
          await post('/EspacoDocumento/v1', documento);
        }else {
          await put('/EspacoDocumento/v1/'+documento.id, documento);
        }
       
        toast.success('Documento salvo com sucesso.')
        CarregaDocumento(tipoDocumento);
    }

    function handleOptionChange(e){
      setTipoDocumento( e.target.value);
      CarregaDocumento(e.target.value);
    }

    return (
             <div className="App" style={{ maxWidth: editorConfig.width, marginTop: 20 }}>
              <div id="sexo">
                              <label className="formbold-form-label">Tipo Documento:</label>
                              <label for="sexo" className="formbold-form-label">
                                   <input type="radio" name="sexo" value="R" className="masculino" onChange={handleOptionChange}
                                   checked={ tipoDocumento === 'R' }/> Regras de Uso</label>
                              
                             

                              <label form="f" className="formbold-form-label">
                                   <input type="radio" name="radio" value="T" className="feminino"  onChange={handleOptionChange}
                                    checked={ tipoDocumento === 'T' }/> Termo de Cessão de Uso</label>
    

                         </div>
                         <hr/>
      <JoditEditor
        value={data}
        config={editorConfig}
        onChange={value => setData(value)}
      />
      <hr />
      <button  className="btn btn-primary" onClick={() => salvar()}>
        Salvar
        </button>
    </div>
       
    );
 }