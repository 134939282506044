import React,{useState} from "react";
import {FiX, FiUpload} from 'react-icons/fi';
import './ModalRegras.css';
import {get} from '../../store/config/register';
import parse from 'html-react-parser';

export default function ModalRegras({conteudo,close}) {
    const [item,setItem]=useState(conteudo)

    return (
        <div className="modalregras">
            <div className="container">
                        <div className="close">
                                    <button className="closeIOS" onClick={() => close()}>
                                    <label><FiX size={20} color="#000"  /></label>
                                    </button>
                                </div>
                                <div className="titulo">
                                    <i className="fa fa-info-circle"></i>                   
                                    <label >{item.espacoNome}</label>
                                </div>
                    <div>
                    </div>
                    <hr />
                    <div>
                       {parse(item.regra)}
                    </div> 
            </div>
        </div>
    );

}