import React, {useState,useEffect} from "react";
import {FiX,FiEdit2} from 'react-icons/fi';
import { useHistory } from "react-router-dom";
import {get} from '../../store/config/register';
import { empresa_Id } from "../../store/config/dadosuser";

import './Grupo.css'

export default function Grupos() {
    const history = useHistory();
    const [grupos,setGrupos] = useState([]);

  async function BuscaGrupo(){
     let gru =await get('/Grupo/v1/Empresa/'+empresa_Id)

     if(gru !== undefined){
        setGrupos(gru);
     }
   }

  
  function Edit(id) {
    history.push('/Grupo/Cadastro/'+id);
  } 

  useEffect(()=>{
    BuscaGrupo();
  },[])

    function renderForm(){
        return (
          <div className="grupo">
            <div className="col-12 d-flex justify-content-start">
                <button className="btn btn-primary" onClick={() => history.push('/Grupo/Cadastro')}>Novo</button>
              
            </div>
            <hr />
                {renderTable()}
          </div>
            
        );
    }

    function renderTable() {
        return (
            <div className='tabela'>
                    <table>
                      <thead>
                        <tr>
                          <th scope="col" className="Grupo">Grupo</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        {grupos === undefined ? null : grupos.map((grupo,index) => {
                          return (
                            <tr key={index}>
                                <td data-label="Grupo">{grupo.nome}</td>
                                <td  data-label="">
                                <button className="action" id="btn1" style={{backgroundColor: '#F99E08' ,marginRight: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={() => Edit(grupo.id)}>
                                  <FiEdit2 color="#FFF" size={17} />
                                  </button>
                                  <button className="action" style={{backgroundColor: '#ff0000',marginRight: "10px" ,marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir Espaço" >
                                  <FiX color="#FFF" size={17} />
                                  </button>
                                  </td>
                              </tr>
                          );
                        })}
                              


                      </tbody>
                    </table>

                  </div>
        );
    }

    return (
        <div>
            {renderForm()}
        </div>
    );
}