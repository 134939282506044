import React,{useState,useEffect} from "react";
import { useHistory,useParams} from "react-router-dom";
import Messagem from "../../../components/mensagem/Messagem";
import { toast } from 'react-toastify';
import {FiX,FiEdit2,FiArrowDown, FiArrowUp,FiBookOpen} from 'react-icons/fi';
import {get,post,put} from '../../../store/config/register';
import ModalReplicacao from "../../Modal/ModalReplicacao";
import './HorarioPadrao.css'

const initialState={
    horaPeriodo: {
         id: 0,
         espaco_Id: 0,
         dia_Semana: 0,
         hora_Inicio: '08:00',
         hora_Fim: '18:00'     
        }
   }

   

export default function HorarioEspecifico(){
    let IdPeriodoHora = 0;
    const {id}=useParams();
    const [horaPeriodo,setHoraPeriodo] = useState(initialState.horaPeriodo); 
    const [horaPeriodos,setHoraPeriodos] = useState([]);
    const [showPostMensagem, setShowPostMessagem] = useState(false);
    const [showPostReplicacao, setShowPostReplicacao] = useState(false);
    const [horaPadraos,setHoraPadraos] =useState([]);
    const [messagem,setMessagem]=useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [horarios,setHorarios]=useState([]);
    const [diasHorario,setDiasHorario]= useState([]);
    const [replicacao,setReplicacao]=useState({})
    const dias = [{id: 0,nome:""},{id: 1,nome:"Domingo"}, {id: 2,nome:"Segunda"}, {id: 3,nome:"Terça"}, {id: 4,nome:"Quarta"}, {id: 5,nome:"Quinta"}, {id: 6,nome:"Sexta"}, {id: 7,nome:"Sábado"}]


    useEffect(()=>{
        CarregaHorasPadrao();
        BuscaHorariosEspecificos();
    },[])

    const handleEpandRow = async(event, semana_id,item) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(semana_id);
    
        let obj = {};
        isRowExpanded ? (obj[semana_id] = false) :  (obj[semana_id] = true);
        setExpandState(obj);
    
        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ?
              currentExpandedRows.filter(semana_id => semana_id !== semana_id) :
              currentExpandedRows.concat(semana_id);
    
        setExpandedRows(newExpandedRows);
    
        // let array =[];
        // selectBlocos.forEach((item)=>{
        //     array.push (unidades.filter((unid) => unid.bloco_Id === item)) ;
        //     setArrayUnidades(array);
        // })
    
      }

    async function CarregaHorasPadrao(){
        if(id !== undefined){
             let hrs = await get('/EspacoPeriodoPadrao/v1/Espaco/'+id);
             if(hrs !== undefined){
                  setHoraPadraos(hrs);
             }
        }
        
    }

    async function BuscaHorariosEspecificos() {
        const hrs =await get('/EspacoPeriodoHora/v1/Espaco/'+ id)
        if(hrs !== undefined){
            let array =[];
            let result =[];
            dias.forEach(dia =>{
                array[dia.id]  =  hrs.filter( f => f.dia_Semana === dia.id);
                result=array;
            })
            setHorarios(result);
            setHoraPeriodos(hrs);
        }
    }

    function renderDias(dia_Semana) {
        if(dias !== undefined) {
            if(dia_Semana !== undefined){
                let result=dias.filter(f => f.id !== dia_Semana)
                return result.map(dia => {
                    return (<option value={dia.id} key={dia.id}> {dia.nome} </option>)
               })
            }else {
                return dias.map(dia => {
                    return (<option value={dia.id} key={dia.id}> {dia.nome} </option>)
               })
            }
             
        }
    }

    function  updateFieldHoraPeriodo(event){
        const hor ={...horaPeriodo}
        hor[event.target.name] =event.target.value
             setHoraPeriodo(hor);
    }

    function TrocaDiaPeriodo(e){
        setHoraPeriodo({...horaPeriodo, dia_Semana: parseInt(e.target.value)})
    }

    function TrocaDiaReplicado(e,dia){
        let array =diasHorario;
        array[dia]=parseInt(e.target.value);
        setDiasHorario(array)
    }

async function Edit(id){
    const hr = await get('/EspacoPeriodoHora/v1/'+id);
    if(hr !== null){
        setHoraPeriodo(hr)
        IdPeriodoHora=hr.id;
    }
}

async function Replicar(dia){
    let data ={espaco_id: parseInt(id),origem_id: dia,destino_id: diasHorario[dia]}
    await post('/EspacoPeriodoHora/v1/Replicacao',data)

     await BuscaHorariosEspecificos();

     toast.success("Horário Replicado");

}

function TablePeriodoHora() {
    return (
        <div className="table2">
            <table>
                <thead>
                    <tr>
                        <th scope="col">Dia da Semana</th>
                        <th scope="col">Início</th>
                        <th scope="col">Fim</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
        
                {horaPadraos === undefined ? null :horaPadraos.map((item, index)=>{
                return(
                <tbody>
                    <tr key={index}>
                        <td data-label="Dia da Semana">{item.semana}</td>
                        <td data-label="Início">{item.hora_Inicio.toString().substring(0,5)}</td>
                        <td data-label="Fim">{item.hora_Fim.toString().substring(0,5)}</td>
                        <td  data-label="">
                        <button className="action" style={{backgroundColor: '#F99E08' ,marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={(e) => handleEpandRow(e, item.dia_Semana,item)} >
                        
                        { expandState[item.dia_Semana] ?
                        (<div>
                             <FiArrowUp color="#FFF" size={17} />
                            
                        </div>
                       ) : 
                            (<div>
                                <FiArrowDown color="#FFF" size={17} />
                                Detalhar
                           </div>
                               )}
                        </button>
                        <button className="action" style={{backgroundColor: '#228B22', marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Replicar"  onClick={async(e) => togglePostReplicacao(item)} >
                                                <FiBookOpen color="#FFF" size={17} />
                                                </button>
                        </td>
                    </tr>
                    <>
                    {expandedRows.includes(item.dia_Semana) ?

                    <tr className="body">
                        <div className="form">
                            <div className="nome-visitante">
                            <div className="hora">
                            <label >Início: </label>
                            <input type='time' min="00:00" max="22:00" name="hora_Inicio" value={horaPeriodo.hora_Inicio} onChange={(e) =>updateFieldHoraPeriodo(e)}  />
                            <label id="lblFimEspecifico">Fim: </label>
                                <input type='time' min="00:00" max="22:00"   name="hora_Fim" value={horaPeriodo.hora_Fim} onChange={(e) =>updateFieldHoraPeriodo(e)} />
                            </div>
                                <button className="btn btn-warning ml-2" id="btnIncluirHorario" onClick={()=>incluirHorario(item.dia_Semana)}>Incluir </button>
                            </div> 
                            <hr/>   
                            <table className="expansive">
                                <thead>
                                    <th scope="col">Início</th>
                                    <th scope="col">Fim</th>
                                    <th scope="col"></th>
                                </thead>     
                                {  horarios[item.dia_Semana].length === 0 ? null : horarios[item.dia_Semana].map((item2,index2) => {
                                return( 
                                    <tbody>
                                        <tr key={index2}>
                                            <td data-label="Início">{item2.hora_Inicio.toString().substring(0,5)}</td>
                                            <td data-label="Fim">{item2.hora_Fim.toString().substring(0,5)}</td>
                                            <td  data-label="">
                                                <button className="action" style={{backgroundColor: '#F99E08' ,marginRight: "20px" }} data-toggle="tooltip" data-placement="bottom" title="Editar" onClick={(e) => Edit(item2.id)} >
                                                <FiEdit2 color="#FFF" size={17} />
                                                </button>
                                                <button className="action" style={{backgroundColor: '#ff0000', marginTop: "10px" }} data-toggle="tooltip" data-placement="bottom" title="Excluir"  onClick={async(e) => await togglePostMessagemModal(item2)} >
                                                <FiX color="#FFF" size={17} />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                );
                                })}
                            </table>
                        </div>
                    </tr>: null
                }
                </>
                </tbody>
                )     
                })}

            </table>
        </div>
    );
}


function togglePostMessagemModal(item){
    setMessagem({...item, messagem:'Confirma exclusão deste dia de semana '+ item.semana +" ?",type: "EspacoPeriodoHora"})
    setShowPostMessagem(!showPostMensagem) //trocando de true pra false
}

async function togglePostReplicacao(item){
    setShowPostReplicacao(!showPostReplicacao) //trocando de true pra false

    let data={espaco_Id: id, dia_Semana: item.dia_Semana};
    setReplicacao({...data})
    if(item === undefined){
      await BuscaHorariosEspecificos();
    }
    
}

async function incluirHorario(dia){
    if(id !== undefined) {
        
        horaPeriodo.espaco_Id =parseInt(id);
        IdPeriodoHora = horaPeriodo.id;
        horaPeriodo.dia_Semana = dia;
        if(IdPeriodoHora === 0){
        await    post('/EspacoPeriodoHora/v1',horaPeriodo);
        }else {
            await  put('/EspacoPeriodoHora/v1/'+IdPeriodoHora,horaPeriodo)
        }
    await toast.success('Dados salvo sucesso.')  

        window.location.reload();
    }

}

   function renderForm(){
    return (
        <div>
            <div>
                
            </div>
            {showPostMensagem && (
                <Messagem
                conteudo={{...messagem}}
                close={togglePostMessagemModal}
                />
                )}
                {showPostReplicacao && (
                 <ModalReplicacao 
                 conteudo={{...replicacao}}
                 close={togglePostReplicacao}
                 />   
                )}
                <br />
                {TablePeriodoHora()}
        </div>
    );   
    
}

    return (
        <div>
            {renderForm()}
        </div>
    );
}