import React, {useState,useEffect} from "react";
import Main from "../../components/template/Main";
import { useHistory } from "react-router-dom";
import {get} from '../../store/config/register';
import { parse,format } from "date-fns"; 
import { login_Id } from "../../store/config/dadosuser";
import useTable from "../../components/table/useTable";
import TableFooter from "../../components/table/TableFooter";

const headerProps = {
    icon: 'comments',
    title: 'Avisos'
  }


export default function AvisosMorador() {
    const [avisos,setAvisos] = useState([]);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(avisos, page, 10);
    const history = useHistory();

    async function buscaAvisos(){
      console.log(login_Id)
        let avi = await get('/Aviso/v1/Login/'+login_Id)
        if(avi !== undefined ){
            setAvisos(avi);
        }
      }

      useEffect(() => {
        buscaAvisos();
      }, [])

      function Visualizar(id) {
        history.push('/Avisos/Cadastro/'+id);
      }

    function renderForm(){
        return (
            <div className="aviso">
               {slice === undefined ? null : slice.map((aviso) => {
                  return (
                    <div className="mensagem" onClick={()=> Visualizar(aviso.id)}>
                      <ul className="cont">
                        <li style={aviso.lido ? {fontWeight: 'normal'}:{fontWeight: 'bold'}}>Data do envio: &nbsp;{aviso.data === '' ? '' : format(parse(aviso.data,"yyyy-MM-dd'T'HH:mm:ss",new Date()),'dd/MM/yyyy')}</li>
                        <li style={aviso.lido ? {fontWeight: 'normal'}:{fontWeight: 'bold'}}>Assunto: &emsp;&emsp;&emsp;&nbsp; {aviso.titulo}</li>
                      </ul>
                    </div>
                  );
      
               })  
               }
                <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
            </div>
            
          );
    }

    return (
        <Main {...headerProps}>
            {renderForm()}
        </Main>
    );
}