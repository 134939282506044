import './Menu.css'
import React from 'react'
import 'font-awesome/css/font-awesome.min.css'
import { isAdmin } from '../../store/config/auth'
import { authKey } from '../../global';
import { useHistory } from 'react-router-dom';
import Main from "./Main";
import ButtonMenu from './ButtonMenu';
import ButtonImage from './ButtonImage';


const regulamento = require('../../assets/imgs/RegrasdosEspaços_80x80.png');

const headerProps = {
    icon: 'file-text-o',
    title: 'Menu Principal'
  }

export default function Menu () {
    const history= new useHistory();
    

  function logout(){
    localStorage.clear(authKey);
    history.push("/");
    window.location.reload();
  }


     function renderForm() {
    return (
       // <div className="menu-area1" >
            <div id="menu-div1">
                {isAdmin() === true? (
                    <div className='menus' id="row1">
                       <ButtonMenu  conteudo={{nome: "Autorizados", color: "#52BE80", icon: 'id-card',link: '/Autorizados'}}/>
                       <ButtonMenu  conteudo={{nome: "Espacos", color:"#808080",icon: 'calendar',link: '/'}}/>
                       <ButtonMenu  conteudo={{nome: "Mural de Avisos", color:"#808080",icon: 'comments',link: '/'}}/>
                       <ButtonMenu  conteudo={{nome: "Documentos", color:"#808080",icon: 'file',link: '/'}}/>
                    </div>
                ) 
                : 
                (
                    <div className='menus form'>
                       <ButtonMenu conteudo={{nome: "Visitantes", color: "#52BE80", type: "fa", icon: 'id-card',link: '/Visitantes'}}/>
                       <ButtonImage conteudo={{nome: "Regras dos Espaços", color:"#FF7F50",icon: 'comments',link: '/Regras', image: regulamento}}/>
                       <ButtonMenu conteudo={{nome: "Reservas", color:"#808080", type: "fa",icon: 'calendar',link: '/'}} />
                       <ButtonMenu conteudo={{nome: "Avisos", color:"#808080",type: "fa",icon: 'comments',link: '/'}}/>
                       <ButtonMenu conteudo={{nome: "Documentos", color:"#808080",type: "fa",icon: 'file',link: '/'}}/>
                    </div>
                )}

                
            {/* </div>

            <div id="mobile1" onClick={() =>handleClick()}>
                <i id="bar1" className={clicked ? 'fas fa-times' : 'fa fa-bars' }></i>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {user.nome}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item className='dropdowitem' href="/">Home</Dropdown.Item>
                        <Dropdown.Item className='dropdowitem' onClick={()=>logout()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div> */}
        </div>
    );

}
return(
    <Main {...headerProps}>
        {renderForm()}
    </Main>
);
}