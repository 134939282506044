import React,{useState,useEffect} from "react";
import { parse,format } from "date-fns"; 
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { get, put } from '../store/config/register.js'
import Main from "../components/template/Main";
import ModalEspaco from "./Modal/ModalEspaco.jsx";
import './Reservas.css'
import ButtonAgenda from "../components/template/ButtonAgenda.jsx";

let idempresa=1;

const headerProps = {
    icon: "object-ungroup",
    title: "Reserva de Espaço",
  }

const initialState ={
    visitante: {
      id: 0,


    }
  }



export default function Reservas(){
    const [showPostModal, setShowPostModal] = useState(false)
    const [origemEspaco, setOrigemEspaco] = useState ({})
    const [espacos, setEspacos]=useState([])
    const history = useHistory()

      
  function togglePostModal(item) {
    setShowPostModal(!showPostModal)
      history.push ('/ReservaEspaco')
    }

    async function BuscaEspacos(){
     const esp = await get('/Espaco/v1')
     if(esp !== null){
        setEspacos(esp)
     }
    }

    useEffect(()=>{
      BuscaEspacos();
    },[])

    function sair() {

    }

    function renderForm(){
        return (
          <div>
            <div>
                <div>
                  <h3>Escolha o Espaço</h3>
                </div>
                <hr/>

                <div>
                    <div className="rowReserva">
                      
                      {espacos !== undefined && espacos.map((espaco)=>{
                       
                        return (
                          <div>
                             <ButtonAgenda conteudo={espaco} />
                          {/* <button className="btnSalao" id="btnRes" type="button"
                          onClick={() => {
                            history.push ('/ReservaEspaco/'+ espaco.id)
                            // setShowPostModal(!showPostModal)
                            // setOrigemEspaco({nome:espaco.nome})
                          }}>
                          {espaco.nome}
                      </button> */}
                          </div>
                        );
                      })}
                      {/* <button className="btnSairReserva" id="btnRes" onClick={() => sair()}>
                        Sair
                      </button> */}

                    </div>

                </div>

                <hr/>

            </div>

            {showPostModal && (
                <ModalEspaco
                origem= {origemEspaco}
                close={togglePostModal}
                />
            )}

          </div>

        );
    }
    return (
    <Main {...headerProps}>
        {renderForm()}
    </Main>
    );
}