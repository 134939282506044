import React,{useState,useEffect} from "react";
import {FiX} from 'react-icons/fi';
import {get,post} from '../../store/config/register'
import { format } from 'date-fns';
import { toast } from "react-toastify";
import './ModalAgenda.css'

const initialState ={
    agenda: {
      id: 0,
      responsavel: '',
      descricao: ''

    }
  }

export default function ModalAgenda({conteudo,close}) {
    
    const [horarios, setHorarios]=useState(conteudo.Horarios);
    const [horarioId,setHorarioId]=useState(0);
    const [agenda,setAgenda]=useState(initialState.agenda);
    const [maiorHoje,setMaiorHoje]= useState(false);
    const [horario, setHorario] = useState(conteudo.Horario );
    const [horarioPadrao,setHorarioPadrao]= useState(conteudo.padrao)
    let diaSemana = 0;
    let data = new Date();

    if(conteudo.data !== undefined){
        diaSemana=conteudo.data.getDay()+1;
        data = conteudo.data;
        agenda.responsavel = conteudo.usuario.unidade + ' - '+conteudo.usuario.bloco

    }

    let hoje =new Date().setHours(0,0,0,0);

        useEffect(()=> {
            if(conteudo.data >= hoje){
                setMaiorHoje(true);
            }
        },[])

    function  updateField(event){
        const age ={...agenda}
          
        age[event.target.name] =event.target.value
          setAgenda(age);
  }

  async function ExisteAgendamento(){
    let inicio="";
    if(horarios !== undefined){
         inicio=horarios.filter(f =>f.id === horarioId)[0].hora_Inicio
    }else {
        inicio =  horarioPadrao[0].hora_Inicio;
    }
    
   const res = await get('/EspacoAgenda/v1/Existe?espaco_id='+conteudo.espaco_id+'&inicio='+ format(conteudo.data,"yyyy-MM-dd ")+inicio);
   return res.existe;
}

    function renderSelectHorario(){    
        if(horarios !== undefined){
            return horarios.map(horario =>{
                return (<option key={horario.id} value={horario.id}>{horario.id === 0 ? '' : `${horario.hora_Inicio} - ${horario.hora_Fim}`} </option>)
            })
        }        
    }

    function trocaHorario(e) {
        setHorarioId(parseInt(e.target.value));
    }

   async function salvarReserva() {
    if(maiorHoje){
        let existe = await ExisteAgendamento();
    if( existe === false) {
        let inicio="";
        if(horarios !== undefined){
             inicio=horarios.filter(f =>f.id === horarioId)[0].hora_Inicio
        }else {
            inicio =  horarioPadrao[0].hora_Inicio;
        }
        let dat ={ espaco_id: parseInt(conteudo.espaco_id), unidade_id: conteudo.usuario.unidade_Id,descricao: agenda.descricao, data: format(conteudo.data,"yyyy-MM-dd'T'")+inicio,hora_inicio: inicio,dia_semana: diaSemana}

        await post('/EspacoAgenda/v1',dat)
        await toast.success('Agendamento realizado com sucesso.')

        window.location.reload();
    }else {
        await toast.warning('Já existe um agendamento para este horário');

    }
    }else {
        await toast.warning('Não é possível fazer agendamentos para datas retroativas');

    }    
    }


     return (
        <div>
            <div className="modal3">
                <div className="container">
                    <button className="closeAgenda" onClick={ close }>
                        <FiX size={23} color="#FFF" />
                        Voltar
                    </button>

                    

                            <label>Dia:</label>
                                <label className="dia" name="responsavel" >{format(data,'dd/MM/yyyy')} </label>
                            </div>
                            <div className="fieldAgenda">
                                <label>Responsável:</label>
                                <input name="responsavel" value={agenda.responsavel} />
                            </div>

                    {horario === true  
                    && 
                    (
                    <div className="fieldAgenda">
                        <label>Quando:</label>
                        <select onChange={(e) => trocaHorario(e)} value={horarioId}>{renderSelectHorario()}</select>
                    </div>

                    )}
                    <div className="fieldAgenda">
                        <label>Descrição:</label>
                        <input type="text" name="descricao" value={agenda.descricao} onChange={(e)=> updateField(e)} />
                    </div>
                    <br />
                    <button className="btn btn-primary" onClick={() => salvarReserva()}>Criar Reserva</button>  
                </div>  
                
            </div>      
     );
}